<template>
        <base-page>
        <titlu-pagina Titlu="Setari Formulare" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />

        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>                        
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>
           
        <el-table :data="Results" v-loading="loading" :row-class-name="tableRowClassName">
            <el-table-column prop='Nume' label='Nume'></el-table-column>
            <el-table-column prop='DataCreare' label='Data Creare'></el-table-column>
            <el-table-column prop='DataValidare' label='Data Validare'></el-table-column>
            <el-table-column label='Activ'>
                <template slot-scope="scope">
                    {{scope.row.EsteActiv == '1' ? 'Da' : 'Nu'}}
                </template>
            </el-table-column>
            <!-- <el-table-column label='Procedura'>
                <template slot-scope="scope">
                    {{scope.row.EsteProcedura == '1' ? 'Da' : 'Nu'}}
                </template>
            </el-table-column> -->
            <el-table-column prop='Stare' label='Stare'></el-table-column>
            <!-- <el-table-column prop='TipContract' label='Tip contract'></el-table-column> -->
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip content="Valideaza">
                        <el-button type="success" icon="el-icon-check" v-if="scope.row.EsteValidat != '1' && scope.row.EsteActiv == '1'" circle @click="validate_item(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Modificare" v-if="scope.row.EsteActiv == '1'">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Sterge" >
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
<el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
<Template-formulare-dialog ref='dlg' @save="refresh_info()" />
</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Template_formulare_dialog from '@/pages/template_formulare/Template_formulare_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    Nume: "template_formulare_list",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Template-formulare-dialog': Template_formulare_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { tipuri_formulare: [] },                            
            Filters: {Nume: '', IdTipContract:'-1'},                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true
        }
    },
    methods: {
        async get_info(){
            var response = await this.post("template_formulare/get_info" );
            this.Info.tipuri_formulare = response.tipuri_formulare
            this.refresh_info();
        },
        async refresh_info(){
            var response        = await this.post("template_formulare/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            this.loading        = false
            //
            this.select_menu_item('template_formulare');
        },
        async validate_item(id){
            let IdFormular = id
            this.$confirm("Sunteti sigur ca doriti sa validati Formularul?", 'Confirmare validare', {
                type: 'warning',
                confirmButtonText: "Valideaza",
                cancelButtonText: "Inapoi"
            }).then(async() => {
                let response = await this.post("template_formulare/valideaza_formular", {id: IdFormular})
                this.$message({type: 'success', message: 'Validare efectuata cu succes'});
                this.refresh_info()                                                                  
            }).catch(() => {
                this.$message({type:'info', message: 'Validare anulata'})
            })
            
        },
        reset(){
            this.Filters = {Nume: '', IdTipContract:'-1'  };                
            this.refresh_info();
        },
        async delete_item( item ){
           this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("template_formulare/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },
        tableRowClassName({row, rowIndex}) {
            if (row.EsteActiv == 0) {
                return 'danger-row';
            } else if (row.EsteActiv == 1 && row.EsteValidat == 0) {
                return 'warning-row';
            }
            return 'success-row';
        },
        show_dialog(id){
            this.$refs.dlg.show_me(id);
        }       
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less">

.top50{
  margin-top: 20px;
}

.filtre{
  .el-input-number
  {
    width: 100% !important;
  }
}

.el-table .warning-row {
    background: rgb(255, 226, 171);
}
.el-table .success-row {
    background: #cbffae;
}
.el-table .danger-row {
    background: #f05b6c;
}

</style>
