<template>
  	<el-dialog title="Template Formular" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			<el-row :gutter="15" >      
				<el-col :md='8'> 
					<el-form-item label='Nume' prop="Nume" >
						<el-input  class='full-width' v-model='selectedObject.Nume' />
					</el-form-item>
				</el-col> 
				<el-col :md='8'> 
					<el-form-item label='Numar zile solutionare' prop="NumarZileSolutionare" >
						<el-input-number class='full-width' v-model='selectedObject.NumarZileSolutionare' :min="1"></el-input-number>
					</el-form-item>
				</el-col> 
				<el-col :md='8'> 
					<el-form-item label='Grup utilizatori asociati' prop="GrupUtilizatoriAsociati" >
						<el-select class='full-width' v-model="selectedObject.GrupUtilizatoriAsociati" multiple placeholder="Select">
							<el-option v-for="item in Info.grupuri_utilizatori" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>       
			</el-row>
			<el-row :gutter="15">
				<el-col :md="8">
					<el-form-item label='Adauga Antet' prop="Header" >
						<el-select class='full-width' v-model="selectedObject.Header" placeholder="Select" @change="selectHeader()">
							<el-option v-for="item in Info.headers" :key="item.Id" :label="item.Nume" :value="item.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :md="8">
					<el-form-item label='Adauga Subsol' prop="Footer">
						<el-select class='full-width' v-model="selectedObject.Footer" placeholder="Select" @change="selectFooter()">
							<el-option v-for="item in Info.footers" :key="item.Id" :label="item.Nume" :value="item.Id"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formular-draggable">
				<el-card class="card-principal">	
					<div slot="header" class="clearfix">
						<span>Ordine rezolvare</span>
					</div>	
					
					<el-col :span="10">
						<el-card ref="cardTabelLista" class="card-tabel-lista">	
							<div slot="header" class="clearfix">
								<span>Functii</span>
							</div>					
							<el-table size="medium" :data="Info.functii_angajati" ref="multipleTable" @selection-change="handleSelectionChange" @row-click="rowClicked">
								<el-table-column type="selection" width="55" ></el-table-column>
								<el-table-column label='Select toate' prop="Nume" ></el-table-column>								
							</el-table>	
						</el-card>			
					</el-col>

					<el-col :span="3" :offset="1" :style="tabelListaHeight">
						<el-button class="buton-adauga-tabel" type="primary" plain @click="adaugaInLista" > ADAUGA <i class="el-icon-right" ></i>  </el-button>
					</el-col>

					<el-col :span="10" >
						<el-card :style="tabelListaHeight">
							<div slot="header" class="clearfix">
								<span>Pasaport de semnaturi</span>
							</div>
							<el-table-draggable :animate="400">
								<el-table size="medium" :data="selectedObject.OrdineRezolvare">
									<el-table-column type="index" width="50px" label="#"></el-table-column>
									<el-table-column label="Nume" prop="Nume"></el-table-column>
									<el-table-column fixed="right" label="Actiuni" width="100px" >
										<template slot-scope="scope" >
											<el-tooltip content="Sterge" >
												<el-button size="mini" type="danger" icon="el-icon-delete" circle @click="delete_item(scope.$index, selectedObject.OrdineRezolvare)"  />
											</el-tooltip>
										</template>
									</el-table-column>
								</el-table>
							</el-table-draggable>
						</el-card>
					</el-col>	
					
				</el-card>	
			</el-row>			

			<el-row :gutter="20">
				<el-col>
					<el-form-item label='Continut' prop="Continut">
						<summernote
						ref="summernote" 
						class="form-control"
						height="500"
						:arata_variabile="true"
						name="formular_template_content"
						:model.sync="selectedObject.Continut"
						@addVariable="showAddVariable"></summernote>
					</el-form-item>
				</el-col>            
			</el-row>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button type="primary" @click="save('selectedObject')" > Salveaza </el-button>
		</span>

		<addVariable ref="addVariable" @insertedVar="getVariabilaInserata" @existingVarsReady="getExistingVars"/>
		<bpImageUpload/>

  	</el-dialog>
</template>

<script>
import BasePage from '@/pages/BasePage'
import FormularEditor from './FormularEditor'
import Summernote from './Summernote'
import AddVariable from './AddVariable'
import BpImageUpload from './BpImageUpload'
import draggable from 'vuedraggable'
import ElTableDraggable from 'element-ui-el-table-draggable';

export default {
    Nume: "Template_formulare_dialog",
    extends: BasePage,    
    components: {
      formularEditor: FormularEditor,
      summernote: Summernote,
      addVariable: AddVariable,
      bpImageUpload: BpImageUpload,
	  draggable,
	  ElTableDraggable
    },
    data () {
      	return {
			showPopup: false,
			mode: 'add',
			selectedObjectTemplate: { Nume: '', NumarZileSolutionare:'', EsteProcedura:'',  Continut: '', OrdineRezolvare:[], GrupUtilizatoriAsociati:[], Header: '', Footer: ''},
			selectedObject: { },               
			Info:{ tipuri_formulare: [], functii_angajati:[], grupuri_utilizatori:[], headers: [], footers: []},
			rules: {
				Nume:     					[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'  }],
				Continut: 					[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }],
				NumarZileSolutionare: 		[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }],
				GrupUtilizatoriAsociati:	[{ required: true, message: 'Campul este obligatoriu', trigger: 'blur' }]
			},
			multipleSelection: [],
			tabelListaHeight:{},
			existingVars:[]
      }
    },
    methods: {
        show_me: async function( id ) {
          	this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
            this.showPopup    	= true;
            if( id == null )
            {
                this.mode = "add";
            }
            else
            {
                this.mode			= "edit";
                var result 			= await this.post("template_formulare/get_info_item_dialog", { id: id } );
                this.selectedObject	= result.Item;
            }
            this.$nextTick(() => {
              	this.$refs.summernote.refresh()
				setTimeout(() => this.matchHeight(), 50);
            })			
			
        },
        async get_info(){
            var response 					= await this.post("template_formulare/get_info_for_dialog" );
			this.Info.functii_angajati		= response.functii_angajati
			this.Info.grupuri_utilizatori	= response.grupuri_utilizatori
			this.Info.headers 				= response.headers
			this.Info.footers 				= response.footers
        },
        async save(formNume) {			
			this.$refs[formNume].validate( async(valid) => {
				if (valid)
				{
					if(this.selectedObject.EsteProcedura == 1 && !this.selectedObject.Stare)
						this.selectedObject.Stare = 'creat';
					await this.post("template_formulare/save", { mode: this.mode, object: this.selectedObject, vars: this.existingVars, objectCopy: this.selectedObjectCopy } );
					this.resetForm(formNume)
					this.$emit("save");
				} else {console.log('eroare validare formular!');}
			});                
		},
		resetForm(formNume) {
			this.$refs[formNume].resetFields()
			this.showPopup = false
		},
        showAddVariable() {
          	this.$refs.addVariable.show()
        },
		async delete_item( index, rows ){           
			this.$confirm('Esti sigur ?', 'Warning', {
				cancelButtonText: 'Renunta',
				type: 'warning'
				}).then(() => {
					rows.splice(index, 1);					
				}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Stergere anulata'
				});          
			});
        },
		selectHeader(){			
			let filtered_header = this.Info.headers.filter(f => f.Id == this.selectedObject.Header)
			this.selectedObject.Continut = filtered_header[0].Continut + this.selectedObject.Continut;
		},
		selectFooter(){
			let filtered_footer = this.Info.footers.filter(f => f.Id == this.selectedObject.Footer)
			this.selectedObject.Continut = this.selectedObject.Continut + filtered_footer[0].Continut;
		},
		adaugaInLista(){
			this.multipleSelection.forEach(m=>this.selectedObject.OrdineRezolvare.push(m))
			this.selectedObject.OrdineRezolvare = this.eliminaIntrariDublate(this.selectedObject.OrdineRezolvare)			
		},
		eliminaIntrariDublate(arrayOfObjects){
			var uniqueArrayOfObjects = arrayOfObjects.filter((thing, index, self) => index === self.findIndex((t) => (t.Id == thing.Id)))
			return uniqueArrayOfObjects
		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		rowClicked(row){			
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		matchHeight() {				
			var height = this.$refs.cardTabelLista.$el.clientHeight
            this.$set(this.tabelListaHeight, 'height', height+'px'); 
        },
		getVariabilaInserata(item){
			console.log('getVariabilaInserata', item);
			this.existingVars.push(item)
			console.log('getVariabilaInserata',this.existingVars);
		},
		getExistingVars(items){			
			this.existingVars = items
			console.log('getExistingVars',this.existingVars);
		}
    },
	watch: {
		'selectedObject.Continut': function(newVal){
			const matches = newVal.matchAll(/{{(.*?)}}/g);
			let vars = Array.from(matches, x => x[1]);

			vars = vars.map(( variabila ) => {
				return variabila.split(' ').join('_');
			})

			// this.existingVars = vars;
			console.log('watcher', vars);
		}
	},

    mounted: function() {
    	this.get_info();
    }
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.formular-draggable{
	margin-bottom: 20px;
	.list {
        background-color: #EBEEF5;
        font-size: 14px;
        .header.el-row {
            background-color: #FFF;
            color: #909399;
            font-weight: 500;            
        }
        .el-row {
            background-color: #FFF;
            transition: background-color .25s ease;
            border-bottom: 1px solid #EBEEF5;
            color: black;
            height: 40px;
            .el-col {
                border-bottom: 1px solid #EBEEF5;
                padding-top: 5px;
                padding-bottom: 5px;
                height: 100%;
                padding-left: 10px;
                padding-right: 10px;
                min-width: 0;
                display: flex;
                
                align-items: center;
                line-height: normal;
            }
        }
    }

	.card-tabel-lista{
		margin-bottom:20px;
	}
	.card-principal{
		vertical-align: middle
	}
	.buton-adauga-tabel{
		position: absolute;
    	top: 50%;
	}
}

</style>